import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, tap } from "rxjs";
import { environment } from "src/environments/environment";
import { Tenant } from "./tenants.models";
import { HttpClient } from "@angular/common/http";

@Injectable({
    providedIn: 'root',
})
export class TenantsService {
    private tenantId = environment.tenantId;
    private _endpoint: string = environment.api.tenantPortalApiBaseUrl + 'tenants';
    
    private _tenant: BehaviorSubject<Tenant> = new BehaviorSubject<Tenant>(this.getTenantFromLocalStorage());

    private _tenants: BehaviorSubject<Tenant[]> = new BehaviorSubject<Tenant[]>([]);

    constructor(private _http: HttpClient) { }

    get tenants$(): Observable<Tenant[]> {
        return this._tenants.asObservable();
    }

    get tenant$(): Observable<Tenant> {
        return this._tenant.asObservable();
    }

    getTenants() {
        return this._http.get(`${this._endpoint}`).pipe(
            tap((tenants: Tenant[]) => this._tenants.next(tenants))
        );
    }

    setTenant(tenant: any) {
        this._tenant.next(tenant);
        localStorage.setItem('tenant', JSON.stringify(tenant));
    }

    private getTenantFromLocalStorage(): Tenant {
        const tenant = localStorage.getItem('tenant');
        if(tenant == null || tenant == undefined) {
            if(this._tenants == null || this._tenants == undefined) {
                return { id: '', name: 'Select tenant ...' };
            }
            return this._tenants.value[0];
        }
        return JSON.parse(tenant);
    }
}